@import "./Colors.scss";

// .ant-radio-button-wrapper-checked {
//   color: red !important;
//   border-color: red !important;
// }

// .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
//   background-color: red !important;
// }

.ant-table {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
}

.ant-table-thead>tr>th {
  background-color: #c33;
  color: #fff;
  text-transform: uppercase;
  font-weight: bolder;
}

.ant-card-head-title,
.ant-modal-title {
  font-family: Kanit, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.ant-menu,
.ant-menu-item {
  font-family: Kanit, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.ant-radio-group {
  font-family:
    "Kanit, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif";
  font-weight: 600;
  text-transform: uppercase;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-item:hover>a {
  background-color: #333;
  color: #fff;
}

.top-menu .ant-dropdown-menu-item {
  font-family: Kanit, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

// .ant-select-item-option-selected {
//   color: #c33;
//   border: 1px solid #c33;
// }

.ant-select-item-option-active {
  background-color: #f2f2f2 !important;
}

.ant-divider-horizontal.ant-divider-with-text-left::before {
  display: none;
}

.ant-divider-inner-text {
  padding-left: 0;
}

.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  border-top: 1px solid #ececec;
}

.ant-btn {
  font-family: 'Kanit', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  // border-width: 1px;
  text-transform: uppercase;
  font-weight: 600;
}

.ant-btn-primary {
  background-color: #c33;
  border-color: #c33;
  color: #fff;
  // padding: 6px 14px;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background-color: #333;
  border-color: #333;
}

.ant-btn-dangerous {
  color: black !important;
}

.ant-btn-sm {
  line-height: 20px;
  // border-color: rgba(0, 0, 0, 0.85);
  // color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.table-row-dark {
  background-color: rgba($color: #c33, $alpha: 0.1);
}

.border-left {
  border-right: 5px solid #f0f0f0 !important;
}

.table-col {
  width: 100px;
}

// FORM
.ant-form-item {
  margin-bottom: 10px;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0 0 2px;
}

// .ant-input,
// .ant-checkbox-inner,
// .ant-select {
//   border: 1px solid rgba($color: #00a8cc, $alpha: 0.5);
//   background: rgba($color: #00a8cc, $alpha: 0.1);
//   // color: #005082;
// }

// .ant-input[disabled] {
//   border: 1px solid red;
// }

// TABLE

// .ant-table.ant-table-small .ant-table-thead>tr>th {
//   background-color: #ccc;
// }

.ant-table-cell {
  padding: 4px !important;
  text-align: center !important;
}

.ant-table-column-sorters {
  padding: 0 !important;
}

.ant-table-column-sorter {
  margin-left: 2px;
  color: #fff;
}

td.ant-table-column-sort {
  background: transparent;
}

.table-font-12 {
  font-size: 12px;
}

.row-top-border>td {
  border-top: 1px solid #777;
}


// Hide arrows in text box
.scoring-system-input .ant-input-number-handler-wrap {
  display: none;
}