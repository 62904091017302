@import "./Colors.scss";

.layout-content {
  background-color: #f2f2f2;
  padding: 10px 10px;

  @media (min-width: 768px) {
    padding: 10px 30px;
  }
}

.login-carousel {
  background-color: $pastel-blue;
}

.center-view {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-height {
  height: -webkit-fill-available;
}

.logo {
  height: 8rem;
  width: 12rem;
}

// .list-view {
//   background-color: #fff;
//   padding: 10px 20px;
//   min-height: 50rem;
// }

// Display
.block {
  display: block;
}

// Margin
.mg-20 {
  margin: 20px;
}

.mh-10 {
  margin: 0 10px;
}

.mh-20 {
  margin: 0 20px;
}

.mv-10 {
  margin: 10px 0;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

// Card
// .white-shadow-card {
//   background-color: white;
//   padding: 15px;
//   border-radius: 5px;
//   margin: 0 5px;
//   box-shadow: 0 0 8px rgba(187, 184, 184, 0.59);
// }

.blue-card {
  background-color: rgba($color: #c33, $alpha: 0.1);
  // background-color: #ddd;
  padding: 8px 12px;
  border-radius: 5px;
  // margin: 0 3px;
  // border: 1.5px solid #1890ff;

  .ant-form-item {
    margin-bottom: 0;
  }
}

// .gradient-view {
//   background-color: blue;
// }

// Alignment
.align-right {
  text-align: right;
}

// .input-view {
//   border: 1px solid $input-border;
//   border-radius: 20px;
// }
.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  // .card {
  //   display: inline-block;
  // }
}

// Font weight

.fw-2 {
  font-weight: 200 !important;
}

.recomm-player-table .ant-table-tbody {
  background: #f5f5f5;
}


// Responsive
@media (max-width: 767px) {
  .sm-flex-scroll {
    display: inline-flex;
    width: 100%;
    overflow: scroll;

    .mr-20 {
      margin-right: 10px;
    }
  }


}