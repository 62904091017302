.custom-draft-popup-row {
  overflow-x: scroll;
  overflow-y: scroll;
  overflow: auto;
}

.custom-draft-popup-modal .ant-modal-content {
  width: fit-content;
  max-width: 80vw;
  margin: auto;
}

.custom-draft-popup-table-teamSelect {
  width: 100%;
  margin: 0 1px;
}

.error-message {
  color: red;
  width: 100%;
}

.ant-modal-footer {
  padding: 16px 16px;
}

.available-players-position-column{
  text-align: left;
  margin-left: 20px;
}
