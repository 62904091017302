@import "../../../assets/scss/Common.scss";

.header {
  // background-color: $bg-gray;
  background-color: #fff !important;
}

.ant-menu {
  background-color: transparent !important;
}

.ant-menu-horizontal {
  border-bottom: none !important;
}

.ant-page-header {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

// .header {
//   position: relative;
//   border-bottom: 0px solid $header-border-bottom-color;
//   height: 60px;
//   z-index: 1;
//   text-align: center;
//   background-color: $bg-gray;
//   padding-top: 5px;
//   padding-bottom: 5px;
// }

// .headerLeft {
//   left: 10px;
//   font-size: 1rem;
//   color: $pastel-blue;
// }

// .headerRight {
//   right: 0px;
//   padding: 10px;
//   font-size: 1rem;
//   color: $pastel-blue;
// }

// .headerText {
//   padding: 0 10px 0 0;
// }

// .headerNotLoggedIn {
//   background-color: $header-notloggin-bgcolor;
//   // border-bottom: 1px solid $header-border-bottom-color;
//   height: 50px;
//   z-index: 1;
//   text-align: center;
//   font-size: 1.8rem;
//   color: $header-text-color;
// }

// .headerTextWithPrefixedSpace {
//   padding: 0 0 0 10px;
// }