

$border-color: #c7c7c7;

/*** Blue ***/
$blue: #005beb;
$pastel-blue: #6fa2f3;
$royal-blue: #327bef;
$blue-border:#b6dffe;
$input-border:#1c8cfc;

/*** Grey ***/
$grey:  #737272;

/*** Red ***/
$red: #af0303;

/*** White ***/
$smoke-white: #f5f5f5;
$white: white;

/*** Black ***/
$black: black;

// Backround color
$bg-gray:#F3F6FC;
$bg-blue-light:#F0F9FF;


$header-loggin-bgcolor: #001529;
$header-notloggin-bgcolor: #001529;
$header-border-bottom-color: royalblue;
$header-text-color: #c7c7c7;
$current-text-hover-color: white;
$current-text-hover-bgcolor: #001529;
